export default [
  {
    path: '',
    name: 'cms',
    component: () => import(/* webpackChunkName: "cms" */ '../views/cms/Index.vue')
  },
  {
    path: 'our-projects',
    component: () => import(/* webpackChunkName: "our-projects" */ '../views/cms/OurProjects.vue'),
    children: [
      {
        path: '',
        name: 'cms.our-projects',
        component: () => import(/* webpackChunkName: "our-projects" */ '../views/cms/projects/Index.vue')
      },
      {
        path: ':id',
        name: 'cms.our-projects.show',
        component: () => import(/* webpackChunkName: "our-projects" */ '../views/cms/projects/Show.vue')
      },
    ]
  },
  {
    path: 'our-services',
    name: 'cms.our-services',
    component: () => import(/* webpackChunkName: "our-services" */ '../views/cms/OurServices.vue')
  },
  {
    path: 'resources',
    name: 'cms.resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/cms/Resources.vue')
  },
  {
    path: 'contact-us',
    name: 'cms.contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/cms/ContactUs.vue')
  },
]