<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p>
            If you have calculated your footprint on a different website or by
            other means, you can enter it here.
          </p>
          <p>
            Quick Tip: you can enter the amount of CO₂ in kilograms or tonnes.
            Tonnes will be converted to kilograms. Kilograms entered will be
            rounded to the nearest whole number.
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="others_form">
          <div class="row">
            <div class="col-md-4">
              <label>Amount</label>
              <amount icon="fal fa-fire" v-model="add.amount" />
            </div>
            <div class="col-md-4">
              <label>Unit</label>
              <select class="form-control" v-model="add.unit">
                <option value="kgs">Kilograms</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Reference <small class="text-muted">(optional)</small></label>
              <amount icon="fal fa-file" type="text" placeholder="Reference" v-model="add.reference" />
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, i) in response" :key="`emission${i}`">
            <td>{{ line.amount }} {{ line.unit }} reference {{ line.reference ? line.reference : 'none' }}</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
// import Renewable from "./inputs/Renewable.vue";
// import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    // Radio,
    // Renewable,
  },

  data() {
    return {
      add: {  },
      added: [],
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    submit() {
      let data = {
        data: this.add,
        form_id: "others_form",
        tab: "others"
      };
      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
