<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p class="">
            Quick Tip: If you get any of your home energy from renewables you
            can enter the amount in the relevant "Renewables ... %" field and
            your footprint will be calculated in proportion to the amount of
            renewables in the mix.
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="at_home_form">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label>Electricity <small>(kWh)</small></label>
              <amount icon="fal fa-bolt" v-model="carbon.electricity.amount" />
              <renewable v-model="carbon.electricity.renewable" />
            </div>
            <div class="col-md-4 mb-3">
              <label>Biomass</label>
              <amount icon="fal fa-fire" v-model="carbon.biomass.amount" />
              <radio
                :options="['Kg', 'kWh']"
                v-model="carbon.biomass.unit"
                id="biomass"
              />
            </div>
            <div class="col-md-4 mb-2">
              <label>LPG</label>
              <amount icon="fal fa-gas-pump" v-model="carbon.lpg.amount" />
              <radio
                :options="['Kg', 'kWh', 'litres']"
                v-model="carbon.lpg.unit"
                id="lpg"
              />
            </div>
            <div class="col-md-4 mb-2">
              <label>Natural Gas</label>
              <amount
                icon="fal fa-gas-pump"
                v-model="carbon.natural_gas.amount"
              />
              <renewable v-model="carbon.natural_gas.renewable" />
              <radio
                :options="['Kg', 'kWh', 'M3']"
                v-model="carbon.natural_gas.unit"
                id="natural_gas"
              />
            </div>
            <div class="col-md-4 mb-3">
              <label>Coal</label>
              <amount icon="fal fa-gas-pump" v-model="carbon.coal.amount" />
              <radio
                :options="['Kg', 'kWh', 'M3']"
                v-model="carbon.coal.unit"
                id="coal"
              />
            </div>
            <div class="col-md-4 mb-3">
              <label>Heating Oil</label>
              <amount
                icon="fal fa-gas-pump"
                v-model="carbon.heating_oil.amount"
              />
              <radio
                :options="['Kg', 'kWh', 'litres']"
                v-model="carbon.heating_oil.unit"
                id="heating_oil"
              />
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, key) in response" :key="`${key}`">
            <td>{{ key }}</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
import Renewable from "./inputs/Renewable.vue";
import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    Radio,
    Renewable,
  },

  data() {
    return {
      carbon: {
        electricity: {
          amount: null,
          renewable: null,
          unit: "kWh",
        },
        biomass: {
          amount: null,
          unit: "Kg",
        },
        lpg: {
          amount: null,
          unit: "Kg",
        },
        natural_gas: {
          amount: null,
          renewable: null,
          unit: "Kg",
        },
        coal: {
          amount: null,
          unit: "Kg",
        },
        heating_oil: {
          amount: null,
          unit: "Kg",
        },
      },
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    submit() {
      let data = this.carbon;
      data.form_id = "at_home_form";
      data.tab = "at-home";
      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
