export default [
  {
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Index.vue')
  },
  {
    path: 'organizations',
    component: () => import(/* webpackChunkName: "dashboard.organizations" */ '../views/dashboard/Organizations.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.organizations',
        component: () => import(/* webpackChunkName: "dashboard.organizations" */ '../views/dashboard/organizations/Index.vue')
      },
      {
        path: 'create',
        name: 'dashboard.organizations.create',
        component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/Create.vue')
      },
      {
        path: ':id',
        name: 'dashboard.organizations.show',
        component: () => import(/* webpackChunkName: "dashboard.organizations.show" */ '../views/dashboard/organizations/Show.vue')
      },
      {
        path: ':id/edit',
        name: 'dashboard.organizations.edit',
        component: () => import(/* webpackChunkName: "dashboard.organizations.edit" */ '../views/dashboard/organizations/Edit.vue')
      },
      {
        path: ':id/projects',
        component: () => import(/* webpackChunkName: "dashboard.organizations.projects" */ '../views/dashboard/organizations/Projects.vue'),
        children: [
          {
            path: 'create',
            component: () => import(/* webpackChunkName: "dashboard.organizations.projects" */ '../views/dashboard/organizations/projects/Create.vue'),
            children: [
              {
                path: '',
                name: 'dashboard.organizations.projects.create',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Index.vue')
              },
              {
                path: 'additionality',
                name: 'dashboard.organizations.projects.create.additionality',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Additionality.vue')
              },
              {
                path: 'benefits',
                name: 'dashboard.organizations.projects.create.benefits',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Benefits.vue')
              },
              {
                path: 'details',
                name: 'dashboard.organizations.projects.create.details',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Details.vue')
              },
              {
                path: 'documentation',
                name: 'dashboard.organizations.projects.create.documentation',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Documentation.vue')
              },
              {
                path: 'finish',
                name: 'dashboard.organizations.projects.create.finish',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Finish.vue')
              },
              {
                path: 'location',
                name: 'dashboard.organizations.projects.create.location',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Location.vue')
              },
              {
                path: 'mitigations',
                name: 'dashboard.organizations.projects.create.mitigations',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Mitigations.vue')
              },
              {
                path: 'summary',
                name: 'dashboard.organizations.projects.create.summary',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Summary.vue')
              },
              {
                path: 'transition',
                name: 'dashboard.organizations.projects.create.transition',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Transition.vue')
              },
              {
                path: 'users',
                name: 'dashboard.organizations.projects.create.users',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Users.vue')
              },
              {
                path: 'validation',
                name: 'dashboard.organizations.projects.create.validation',
                component: () => import(/* webpackChunkName: "dashboard.organizations.create" */ '../views/dashboard/organizations/projects/create/Validation.vue')
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'projects',
    component: () => import(/* webpackChunkName: "dashboard.projects" */ '../views/dashboard/Projects.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.projects',
        component: () => import(/* webpackChunkName: "dashboard.projects" */ '../views/dashboard/projects/Index.vue')
      },
      {
        path: ':id',
        name: 'dashboard.projects.show',
        component: () => import(/* webpackChunkName: "dashboard.projects" */ '../views/dashboard/projects/Show.vue')
      },
    ]
  },
  {
    path: 'my-projects',
    component: () => import(/* webpackChunkName: "dashboard.my-projects" */ '../views/dashboard/MyProjects.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.my-projects',
        component: () => import(/* webpackChunkName: "dashboard.my-projects" */ '../views/dashboard/my-projects/Index.vue')
      },
      {
        path: ':id',
        name: 'dashboard.my-projects.show',
        component: () => import(/* webpackChunkName: "dashboard.my-projects" */ '../views/dashboard/my-projects/Show.vue')
      },
    ]
  },
  {
    path: 'orders',
    component: () => import(/* webpackChunkName: "dashboard.orders" */ '../views/dashboard/Orders.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.orders',
        component: () => import(/* webpackChunkName: "dashboard.orders" */ '../views/dashboard/orders/Index.vue')
      },
      {
        path: ':id',
        name: 'dashboard.orders.show',
        component: () => import(/* webpackChunkName: "dashboard.orders" */ '../views/dashboard/orders/Show.vue')
      },
    ]
  },
  {
    path: 'my-cart',
    name: 'dashboard.my-cart',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/MyCart.vue')
  },
  {
    path: 'checkout',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Checkout.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.checkout',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/checkout/Index.vue')
      },
      {
        path: 'preview',
        name: 'dashboard.checkout.preview',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/checkout/Preview.vue')
      },
      {
        path: 'payment',
        name: 'dashboard.checkout.payment',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/checkout/Payment.vue')
      },
      {
        path: ':id',
        name: 'dashboard.checkout.success',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/checkout/Success.vue')
      },
    ]
  },
  {
    path: 'carbon-calculator',
    name: 'dashboard.carbon-calculator',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/CarbonCalculator.vue')
  },
  {
    path: 'payments',
    name: 'dashboard.payments',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Payments.vue')
  },
  {
    path: 'shared-files',
    name: 'dashboard.shared-files',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/SharedFiles.vue')
  },
  {
    path: 'my-account',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.my-account',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/my-account/Index.vue')
      },
      {
        path: 'security',
        name: 'dashboard.my-account.security',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/my-account/Security.vue')
      },
      {
        path: 'notifications',
        name: 'dashboard.my-account.notifications',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/my-account/Notifications.vue')
      },
    ]
  },
  {
    path: 'resources',
    name: 'dashboard.resources',
    component: () => import(/* webpackChunkName: "dashboard.resources" */ '../views/dashboard/Resources.vue')
  },
  {
    path: 'contact-us',
    name: 'dashboard.contact-us',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/ContactUs.vue')
  },
  {
    path: 'alerts',
    name: 'dashboard.alerts',
    component: () => import(/* webpackChunkName: "dashboard.alerts" */ '../views/dashboard/Alerts.vue')
  },
  {
    path: 'messages',
    name: 'dashboard.messages',
    component: () => import(/* webpackChunkName: "dashboard.messages" */ '../views/dashboard/Messages.vue')
  },
  {
    path: 'search',
    name: 'dashboard.search',
    component: () => import(/* webpackChunkName: "dashboard.search" */ '../views/dashboard/Search.vue')
  },
]