export default [
  {
    path: '',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Index.vue')
  },
  {
    path: 'organizations',
    component: () => import(/* webpackChunkName: "admin.organizations" */ '../views/admin/Organizations.vue'),
    children: [
      {
        path: '',
        name: 'admin.organizations',
        component: () => import(/* webpackChunkName: "admin.organizations" */ '../views/admin/organizations/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.organizations.show',
        component: () => import(/* webpackChunkName: "admin.organizations" */ '../views/admin/organizations/Show.vue')
      },
    ]
  },
  {
    path: 'projects',
    component: () => import(/* webpackChunkName: "admin.projects" */ '../views/admin/Projects.vue'),
    children: [
      {
        path: '',
        name: 'admin.projects',
        component: () => import(/* webpackChunkName: "admin.projects" */ '../views/admin/projects/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.projects.show',
        component: () => import(/* webpackChunkName: "admin.projects" */ '../views/admin/projects/Show.vue')
      },
    ]
  },
  {
    path: 'alerts',
    name: 'admin.alerts',
    component: () => import(/* webpackChunkName: "admin.alerts" */ '../views/admin/Alerts.vue')
  },
  {
    path: 'messages',
    name: 'admin.messages',
    component: () => import(/* webpackChunkName: "admin.messages" */ '../views/admin/Messages.vue')
  },
  {
    path: 'search',
    name: 'admin.search',
    component: () => import(/* webpackChunkName: "admin.search" */ '../views/admin/Search.vue')
  },
  {
    path: 'settings',
    name: 'admin.settings',
    component: () => import(/* webpackChunkName: "admin.settings" */ '../views/admin/Settings.vue')
  },
  {
    path: 'my-account',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/MyAccount.vue'),
    children: [
      {
        path: '',
        name: 'admin.my-account',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/my-account/Index.vue')
      },
      {
        path: 'security',
        name: 'admin.my-account.security',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/my-account/Security.vue')
      },
      {
        path: 'notifications',
        name: 'admin.my-account.notifications',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/my-account/Notifications.vue')
      },
    ]
  },
  {
    path: 'orders',
    component: () => import(/* webpackChunkName: "admin.orders" */ '../views/admin/Orders.vue'),
    children: [
      {
        path: '',
        name: 'admin.orders',
        component: () => import(/* webpackChunkName: "admin.orders" */ '../views/admin/orders/Index.vue')
      },
      {
        path: ':id',
        name: 'admin.orders.show',
        component: () => import(/* webpackChunkName: "admin.orders" */ '../views/admin/orders/Show.vue')
      },
    ]
  },
]