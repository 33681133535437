<template>
  <div class="input-group">
    <div class="form-floating flex-grow-1">
      <input
        :type="show ? 'text' : 'password'"
        class="form-control"
        placeholder="Confirm Password"
        :id="id"
        :name="name"
        v-model="password"
      />
      <label :for="id">Confirm Password</label>
    </div>
    <a href="#" class="input-group-text" @click.prevent="show = ! show">
      <i :class="`fas fa-${ show ? 'eye-slash' : 'eye'}`"></i>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: this.value,
      show: false
    }
  },

  props: {
    id: {
      default() {
        return 'password'
      }
    },

    value: {
      default() {
        return ''
      }
    },

    name: {
      default() {
        return 'password'
      }
    }
  },

  watch: {
    password() {
      this.$emit('input', this.password)
    }
  }
};
</script>
