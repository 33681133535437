<template>
  <div id="app">
    <div v-if="loading" class="main-loader">
      <div class="text-center">
        <img src="/logo.svg" alt="logo" class="d-block" style="height: 100px;" />
        <img src="/assets/dots.svg" alt="loading" class="mt-3" style="height: 40px;">
      </div>
    </div>
    <template v-if="!loading">
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/init').then(response => {
        if(response.data.auth) {
          this.$store.dispatch('setAuthUser', response.data.user)
        }
        
        this.loading = false
      })
    },
  },
};
</script>
