<template>
  <div class="floating-select">
    <input type="hidden" class="form-control" :name="name">
    <label>{{ placeholder }}</label>
    <v-select :options="options" :label="label" v-model="selected" :placeholder="placeholder" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect
  },

  mounted() {
    let selected_option = this.options.filter(option => {
      return option.id == this.value
    })[0]

    if(selected_option) {
      this.selected = selected_option
    }
  },

  data() {
    return {
      selected: null
    }
  },

  props: {
    options: {
      default() {
        return []
      }
    },

    label: {
      default() {
        return ''
      }
    },

    value: {
      default() {
        return ''
      }
    },

    placeholder: {
      default() {
        return ''
      }
    },

    target: {
      default() {
        return 'id'
      }
    },

    name: {
      default() {
        return ''
      }
    }
  },

  watch: {
    selected() {
      if(this.selected) {
        this.$emit('input', this.selected[this.target])
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>