<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p>
            Unless you just would like to calculate the footprint of a single trip, please enter your complete annual mileage for each type of transport.
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="public_transport">
          <div class="row">
            <div class="col-md-6 mb-6">
              <label>Vehicle Type</label>
              <select class="form-control" v-model="add.vehicle_type">
                <option value="bus">Bus/Coach</option>
                <option value="train">Train</option>
              </select>
            </div>
            <div class="col-md-5 mb-2">
              <label>Distance</label>
              <amount icon="fal fa-car" v-model="add.distance" />
              <radio :options="['kms', 'miles']" v-model="add.unit" />
            </div>
            <div class="col-md-1 centered">
              <a href="#" class="btn btn-sm btn-primary w-100" @click.prevent="attToTable(add)"
                ><i class="fa fa-plus"></i
              ></a>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Vehicle Type</th>
                    <th>Distance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="added.length == 0">
                    <td colspan="3">There is no data to display.</td>
                  </tr>
                  <tr v-for="(line, i) in added" :key="`private-vehicle-${i}`">
                    <td>{{ line.vehicle_type }}</td>
                    <td>{{ line.distance }}</td>
                    <td>
                      <a href="#" @click.prevent="added.splice(i, 1)"><i class="fa fa-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, i) in response" :key="`emission${i}`">
            <td>{{ line.vehicle_type }} covering {{ line.distance }} {{ line.unit }}</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
// import Renewable from "./inputs/Renewable.vue";
import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    Radio,
    // Renewable,
  },

  data() {
    return {
      add: { vehicle_type: "bus", distance: 1, unit: 'kms' },
      added: [],
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    attToTable(add) {
      this.added.push(add)
      this.add = { vehicle_type: "bus", distance: 1, unit: 'kms' }
    },

    submit() {
      let data = {
        data: this.added,
        form_id: "public_transport",
        tab: "public-transport"
      };

      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
