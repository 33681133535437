<template>
  <div class="input-group mb-2">
    <input type="number" class="form-control" placeholder="Renewable: 0" v-model="val" />
    <span class="input-group-text"><i class="fal fa-percent"></i></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: this.value
    }
  },

  props: {
    value: {
      default() {
        return null
      }
    }
  },

  watch: {
    val() {
      this.$emit('input', this.val)
    }
  }
};
</script>
