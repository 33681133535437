export default {
  install(Vue) {
    Vue.load = (id) => {
      let form = document.getElementById(id);
      form.classList.add('position-relative')

      Object.values(form.getElementsByClassName("alert")).forEach((el) => {
        el.remove();
      });

      let loader = document.createElement("div");
      loader.classList.add("form-loader");
      let spinner = document.createElement("img");
      spinner.src = "/assets/dots.svg";
      loader.append(spinner);
      form.prepend(loader);
    };

    Vue.stopLoading = (msg = null) => {
      Object.values(document.getElementsByClassName("input-error")).forEach(
        (e) => {
          e.remove();
        }
      );

      Object.values(document.getElementsByClassName("alert")).forEach((el) => {
        el.remove();
      });

      Object.values(document.getElementsByClassName("form-loader")).forEach(
        (el) => {
          if (msg) {
            let form = el.parentElement;
            let message = document.createElement("div");
            message.classList.add("alert");
            message.classList.add("alert-danger");
            message.classList.add("mb-2");
            message.innerHTML = msg;
            form.prepend(message);
          }

          el.remove();
        }
      );
    };

    Vue.handle = (response) => {
      Object.values(document.getElementsByClassName("form-loader")).forEach(
        (el) => {
          el.remove();
        }
      );

      if (response.status == 422) {
        if (response.config.data) {
          let data = {}

          if(typeof response.config.data == 'string') {
            data = JSON.parse(response.config.data);
          } else {
            data = { form_id: response.config.data.get('form_id') }
          }
          
          if (data.form_id) {
            Vue.handleInputs(data.form_id, response);
          }
        }
      }
    };

    Vue.handleInputs = (id, response) => {
      let form = document.getElementById(id);

      Object.values(form.getElementsByClassName("input-error")).forEach((e) => {
        e.remove();
      });

      let inputs = Object.values(form.getElementsByClassName("form-control"));
      let errors = response.data.errors;

      inputs.forEach((input) => {
        if (Object.keys(errors).indexOf(input.name) >= 0) {
          let parent = input.parentElement;
          let msg = document.createElement("span");
          msg.classList.add("input-error");
          msg.innerHTML = errors[input.name][0];

          parent.append(msg);
        }
      });
    };

    Vue.axios.interceptors.request.use(
      function (config) {
        let data = config.data;

        if (data) {
          if(data instanceof FormData) {
            if (data.get('form_id')) {
              Vue.load(data.get('form_id'));
            }
          } else {
            if (data.form_id) {
              Vue.load(data.form_id);
            }
          }
        }

        return config;
      },
      function (error) {
        Vue.stopLoading();
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      function (response) {
        Vue.stopLoading();
        return response;
      },
      function (error) {
        if (error.response) {
          Vue.handle(error.response);
        } else {
          Vue.stopLoading(
            "There was an error processing your request. Please try again later."
          );
        }

        return Promise.reject(error);
      }
    );
  },
};
