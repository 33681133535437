<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p>
            Quick Tip: If different people in your household do different things for their meals and you want to include them, add the current food footprint to your footprint and then you can return to this page and add more food footprints - one for each person.
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="food_form">
          <div class="row">
            <div class="col-md-3 mb-3">
              <label>High meat-eater</label>
              <select class="form-control" v-model="add.high_meat">
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Medium meat-eater</label>
              <select class="form-control" v-model="add.medium_meat">
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Low meat-eater</label>
              <select class="form-control" v-model="add.low_meat">
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Fish eater</label>
              <select class="form-control" v-model="add.fish">
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Vegeterian</label>
              <select class="form-control" v-model="add.vegeterian">
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Vegan</label>
              <select class="form-control" v-model=add.vegan>
                <option value="1">1 day a week</option>
                <option value="2">2 day a week</option>
                <option value="3">3 day a week</option>
                <option value="4">4 day a week</option>
                <option value="5">5 day a week</option>
                <option value="6">6 day a week</option>
                <option value="7">7 day a week</option>
              </select>
            </div>
            <div class="col-md-3 mb-3">
              <label>No. of People</label>
              <amount icon="fa fa-users" v-model="add.people" />
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, i) in response" :key="`emission${i}`">
            <td>{{ line.category }} eaten {{ line.amount }} times a week</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
// import Renewable from "./inputs/Renewable.vue";
// import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    // Radio,
    // Renewable,
  },

  data() {
    return {
      add: { high_meat: 1, medium_meat: 1, low_meat: 1, fish: 1, vegeterian: 1, vegan: 1, people: 1 },
      added: [],
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    submit() {
      let data = {
        data: this.add,
        form_id: "food_form",
        tab: "food"
      };
      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
