<template>
  <div class="container">
    <ul
      class="nav nav-tabs calc-tabs"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="at-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#at-home-tab-pane"
          type="button"
          role="tab"
          aria-controls="at-home-tab-pane"
          aria-selected="true"
        >
          <i class="fa fa-home me-2"></i>At Home
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="private-transport-tab"
          data-bs-toggle="tab"
          data-bs-target="#private-transport-tab-pane"
          type="button"
          role="tab"
          aria-controls="private-transport-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-car me-2"></i>Private Transport
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="public-transport-tab"
          data-bs-toggle="tab"
          data-bs-target="#public-transport-tab-pane"
          type="button"
          role="tab"
          aria-controls="public-transport-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-bus me-2"></i>Public Transport
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="flight-tab"
          data-bs-toggle="tab"
          data-bs-target="#flight-tab-pane"
          type="button"
          role="tab"
          aria-controls="flight-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-plane me-2"></i>Flight
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="food-tab"
          data-bs-toggle="tab"
          data-bs-target="#food-tab-pane"
          type="button"
          role="tab"
          aria-controls="food-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-glass me-2"></i>Food
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="expenditure-tab"
          data-bs-toggle="tab"
          data-bs-target="#expenditure-tab-pane"
          type="button"
          role="tab"
          aria-controls="expenditure-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-money me-2"></i>Expenditure
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="others-tab"
          data-bs-toggle="tab"
          data-bs-target="#others-tab-pane"
          type="button"
          role="tab"
          aria-controls="others-tab-pane"
          aria-selected="false"
        >
          <i class="fa fa-bars me-2"></i>Others
        </button>
      </li>
    </ul>
    <div class="tab-content mb-3 pe-3 carbon-calculator-content">
      <div
        class="tab-pane fade show active"
        id="at-home-tab-pane"
        role="tabpanel"
        aria-labelledby="at-home-tab"
        tabindex="0"
      >
        <at-home />
      </div>
      <div
        class="tab-pane fade"
        id="private-transport-tab-pane"
        role="tabpanel"
        aria-labelledby="private-transport-tab"
        tabindex="0"
      >
        <private-transport />
      </div>
      <div
        class="tab-pane fade"
        id="public-transport-tab-pane"
        role="tabpanel"
        aria-labelledby="public-transport-tab"
        tabindex="0"
      >
        <public-transport />
      </div>
      <div
        class="tab-pane fade"
        id="flight-tab-pane"
        role="tabpanel"
        aria-labelledby="flight-tab"
        tabindex="0"
      >
        <flight />
      </div>
      <div
        class="tab-pane fade"
        id="food-tab-pane"
        role="tabpanel"
        aria-labelledby="food-tab"
        tabindex="0"
      >
        <food />
      </div>
      <div
        class="tab-pane fade"
        id="expenditure-tab-pane"
        role="tabpanel"
        aria-labelledby="expenditure-tab"
        tabindex="0"
      >
        <expenditure />
      </div>
      <div
        class="tab-pane fade"
        id="others-tab-pane"
        role="tabpanel"
        aria-labelledby="others-tab"
        tabindex="0"
      >
        <others />
      </div>
    </div>
  </div>
</template>

<script>
import AtHome from './AtHome.vue'
import Expenditure from './Expenditure.vue'
import Flight from './Flight.vue'
import Food from './Food.vue'
import Others from './Others.vue'
import PrivateTransport from './PrivateTransport.vue'
import PublicTransport from './PublicTransport.vue'

export default {
  components: {
    AtHome, Expenditure, Flight, Food, Others, PrivateTransport, PublicTransport
  }
};
</script>
