import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardRoutes from './dashboard'
import AdminRoutes from './admin'
import CmsRoutes from './cms'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/our-projects',
    component: () => import(/* webpackChunkName: "our-projects" */ '../views/OurProjects.vue'),
    children: [
      {
        path: '',
        name: 'our-projects',
        component: () => import(/* webpackChunkName: "our-projects" */ '../views/projects/Index.vue')
      },
      {
        path: ':id',
        name: 'our-projects.show',
        component: () => import(/* webpackChunkName: "our-projects" */ '../views/projects/Show.vue')
      },
    ]
  },
  {
    path: '/our-services',
    name: 'our-services',
    component: () => import(/* webpackChunkName: "our-services" */ '../views/OurServices.vue')
  },
  {
    path: '/carbon-calculator',
    name: 'carbon-calculator',
    component: () => import(/* webpackChunkName: "carbon-calculator" */ '../views/CarbonCalculator.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/Resources.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue')
  },
  {
    path: '/verify-email/:token',
    name: 'verify-email',
    component: () => import(/* webpackChunkName: "verify-email" */ '../views/auth/VerifyEmail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword.vue')
  },
  {
    path: '/recover-account/:token',
    name: 'recover-account',
    component: () => import(/* webpackChunkName: "recover-account" */ '../views/auth/RecoverAccount.vue')
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    children: DashboardRoutes,
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: AdminRoutes,
  },
  {
    path: '/cms',
    component: () => import(/* webpackChunkName: "cms" */ '../views/CMS.vue'),
    children: CmsRoutes,
  },
  {
    path: '/*',
    name: 'error-404',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
		return { x: 0, y: 0 };
	},
  routes
})

export default router
