<template>
  <div class="radio-group">
    <div class="option" v-for="(option, i) in options" :key="`option-${i}`">
      <input
        type="radio"
        class="btn-check"
        :name="id"
        :id="`${id}-${i}`"
        :value="option"
        autocomplete="off"
        v-model="val"
      />
      <label class="" :for="`${id}-${i}`">{{ option }}</label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: this.value
    }
  },

  props: {
    options: {
      default() {
        return []
      }
    },

    value: {
      default() {
        return null
      }
    },

    id: {
      default() {
        return 'option'
      }
    }
  },

  watch: {
    val() {
      this.$emit('input', this.val)
    }
  }
};
</script>
