<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p>
            Quick Tip: You can enter IATA codes to quickly find an airport name
            - the code will be printed on your ticket, or you can check this
            list.
          </p>
          <p>
            You can calculate up to 10 flights at a time - either individual
            trips, or the legs of a long-haul flight. For a long-haul flight set
            each leg to "Single".
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="flight_form">
          <div class="row">
            <div class="col-md-4 mb-2">
              <label>From</label>
              <amount icon="fal fa-plane" placeholder="From" type="text" v-model="add.from" />
            </div>
            <div class="col-md-4 mb-2">
              <label>To</label>
              <amount icon="fal fa-plane" placeholder="To" type="text" v-model="add.to" />
            </div>
            <div class="col-md-4 mb-2">
              <label>Return</label>
              <select class="form-control" v-model="add.return">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div class="col-md-4 mb-2">
              <label>Class</label>
              <select class="form-control" v-model="add.class">
                <option value="first">First</option>
                <option value="business">Business</option>
                <option value="economy">Economy</option>
              </select>
            </div>
            <div class="col-md-4 mb-2">
              <label>Passengers</label>
              <amount icon="fa fa-users" v-model="add.passengers" />
            </div>
            <div class="col-md-4 centered">
              <a href="#" @click.prevent="addToTable(add)" class="btn btn-primary btn-sm w-100"><i class="fa fa-plus me-2"></i>Add</a>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Return</th>
                    <th>Class</th>
                    <th>Passengers</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="added.length == 0">
                    <td colspan="6">There is no data to display.</td>
                  </tr>
                  <tr v-for="(line, i) in added" :key="`added-${i}`">
                    <td>{{ line.from }}</td>
                    <td>{{ line.to }}</td>
                    <td>{{ line.return }}</td>
                    <td>{{ line.class }}</td>
                    <td>{{ line.passengers }}</td>
                    <td>
                      <a href="#" @click.prevent="added.splice(i, 1)"><i class="fa fa-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, i) in response" :key="`emission${i}`">
            <td>from {{ line.from }} to {{ line.to }} in {{ line.class }} class for {{ line.passengers }} passengers</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
// import Renewable from "./inputs/Renewable.vue";
// import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    // Radio,
    // Renewable,
  },

  data() {
    return {
      add: { from: null, to: null, return: 'no', class: 'economy', passengers: 1 },
      added: [],
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    addToTable(add) {
      this.added.push(add)
      this.add = { from: null, to: null, return: 'no', class: 'economy', passengers: 1 }
    },

    submit() {
      let data = {
        data: this.added,
        form_id: "flight_form",
        tab: "flight"
      };
      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
