<template>
  <div class="loading-spinner">
    <img src="/assets/dots.svg" alt="spinner" style="height: 50px;">
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background: #fcfcfc;
}
</style>