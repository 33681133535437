<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="bg-light-blue p-3 h-100">
          <p>
            To the nearest £ how much does your household spend in a year on the following items? Any items bought second hand shouldn't be included.
          </p>
        </div>
      </div>
      <div class="col-md-9 py-3">
        <form id="expenditure_form">
          <div class="row">
            <div class="col-md-3 mb-3">
              <label>Eating Out</label>
              <amount icon="fal fa-money-bill" v-model="add.eating_out" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Car Maintanance</label>
              <amount icon="fal fa-money-bill" v-model="add.car_maintanance" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Clothing</label>
              <amount icon="fal fa-money-bill" v-model="add.clothing" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Furniture</label>
              <amount icon="fal fa-money-bill" v-model="add.furniture" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Domestic Water</label>
              <amount icon="fal fa-money-bill" v-model="add.domestic_water" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Phone & Internet</label>
              <amount icon="fal fa-money-bill" v-model="add.phone_internet" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Computers & Electronics</label>
              <amount icon="fal fa-money-bill" v-model="add.computers_electronics" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Electrical Appliances</label>
              <amount icon="fal fa-money-bill" v-model="add.electrical_appliances" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Postage & Couriers</label>
              <amount icon="fal fa-money-bill" v-model="add.postage_couriers" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Magazines & Books</label>
              <amount icon="fal fa-money-bill" v-model="add.magazines_books" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Stationery</label>
              <amount icon="fal fa-money-bill" v-model="add.stationery" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Hair & Self Care</label>
              <amount icon="fal fa-money-bill" v-model="add.hair_self_care" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Pet Food</label>
              <amount icon="fal fa-money-bill" v-model="add.pet_food" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Hotel Stays</label>
              <amount icon="fal fa-money-bill" v-model="add.hotel_stays" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Insurance</label>
              <amount icon="fal fa-money-bill" v-model="add.insurance" />
            </div>
            <div class="col-md-3 mb-3">
              <label>Legal Services</label>
              <amount icon="fal fa-money-bill" v-model="add.legal_services" />
            </div>
          </div>
          <div class="shadow border mt-5 p-3">
            <a
              href="#"
              class="btn btn-primary btn-sm me-2"
              @click.prevent="submit"
              ><i class="fa fa-calculator me-2"></i> Calculate</a
            >
            <a
              href="#"
              class="btn btn-primary btn-sm float-end"
              @click.prevent="calculated = false"
              ><i class="fa fa-redo me-2"></i> Reset</a
            >
          </div>
        </form>
      </div>
    </div>
    <hr />
    <div class="p-3" v-if="calculated">
      <h4 class="underlined">Carbon Emissions Results</h4>
      <table class="table mb-5 mt-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>CO<sub>2</sub> Emissions</th>
            <th>Unit</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, i) in response" :key="`emission${i}`">
            <td>{{ line.category }} for KES {{ line.amount }} per year</td>
            <td>{{ line.co2e }}</td>
            <td>{{ line.co2e_unit }}</td>
            <td>{{ line.co2e * price }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th>{{ total_carbon }}</th>
            <th></th>
            <th>{{ total_amount }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Amount from "./inputs/Amount.vue";
// import Renewable from "./inputs/Renewable.vue";
// import Radio from "./inputs/Radio.vue";

export default {
  components: {
    Amount,
    // Radio,
    // Renewable,
  },

  data() {
    return {
      add: {},
      added: [],
      response: {},
      price: 0,
      calculated: false,
    };
  },

  methods: {
    submit() {
      let data = {
        data: this.add,
        form_id: "expenditure_form",
        tab: "expenditure"
      };
      this.calculated = false;

      this.$axios.post("/api/v1/carbon-calculator", data).then((response) => {
        this.response = response.data.emissions;
        this.price = response.data.price;
        this.calculated = true;
      });
    },
  },

  computed: {
    total_carbon() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total;
    },

    total_amount() {
      let total = 0;

      Object.values(this.response).forEach((line) => {
        total += parseInt(line.co2e);
      });

      return total * this.price;
    },
  },
};
</script>
