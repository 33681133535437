<template>
  <div>
    <div class="image-picker" v-show="!loading">
      <input
        type="file"
        class="d-none"
        :id="id"
        @change="preview"
        ref="select_image"
        :name="name"
      />
      <div class="select-image" v-if="!image_selected">
        <div class="text-center">
          <a href="#" @click.prevent="selectImage">
            <img :src="placeholder" alt="upload" :class="imageClass" />
          </a>
        </div>
      </div>
      <div class="image-preview" v-if="image_selected">
        <div class="image">
          <img :src="image_preview" alt="image" :class="imageClass" />
        </div>
        <div class="actions">
          <div class="centered h-100">
            <a href="#" @click.prevent="selectImage" class="me-2"
              ><i class="fa fa-pencil"></i
            ></a>
            <a href="#" @click.prevent="removeImage"
              ><i class="fa fa-trash"></i
            ></a>
          </div>
        </div>
      </div>
      <span v-if="!is_image" class="has-error text-danger"
        >Invalid Image Selected. Image must be of type PNG or JPEG</span
      >
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      if (this.value) {
        this.image_preview = this.value;
        this.image_selected = true;
      } else {
        this.preview();
      }

      this.loading = false;
    }, 1000);
  },

  data() {
    return {
      image_preview: null,
      image_selected: false,
      loading: true,
    };
  },

  props: {
    value: {
      default() {
        return null;
      },
    },

    name: {
      default() {
        return "name";
      },
    },

    id: {
      default() {
        return "select-image";
      },
    },

    placeholder: {
      default() {
        return "/assets/cloud-upload.svg";
      },
    },

    imageClass: {
      default() {
        return "";
      },
    },
  },

  methods: {
    selectImage() {
      document.getElementById(this.id).click();
    },

    removeImage() {
      document.getElementById(this.id).value = null;
      this.preview();
    },

    preview() {
      let image = this.$refs.select_image.files[0];

      if (image == undefined) {
        this.image_selected = false;
        this.image_preview = null;
      } else {
        this.image_selected = true;

        let reader = new FileReader();

        reader.onload = (e) => {
          this.image_preview = e.target.result;
        };

        reader.readAsDataURL(image);
      }
    },
  },

  watch: {
    image_preview() {
      this.$emit("input", this.image_preview);
    },
  },

  computed: {
    is_image() {
      if (this.image_preview == null) {
        return true;
      }

      if (
        this.image_preview.indexOf("data:image/png") >= 0 ||
        this.image_preview.indexOf("data:image/jpeg") >= 0 ||
        this.image_preview.indexOf("http") >= 0
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
