import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Components
import ImagePicker from './components/ImagePicker.vue'
Vue.component('image-picker', ImagePicker)

import Alert from './components/Alert.vue'
Vue.component('alert', Alert)

import Password from './components/Password.vue'
Vue.component('password', Password)

import Loading from './components/Loading.vue'
Vue.component('loading', Loading)

import VueSelect from './components/VueSelect.vue'
Vue.component('vue-select', VueSelect)

import VLazyImage from "v-lazy-image/v2";
Vue.component('v-lazy-image', VLazyImage)

import TinyMce from './components/TinyMce.vue'
Vue.component('tiny-mce', TinyMce)

import DatePicker from 'vuejs-datepicker'
Vue.component('date-picker', DatePicker)

import CarbonCalculator from './components/carbon-calculator/Index'
Vue.component('carbon-calculator', CarbonCalculator)

import { GChart } from 'vue-google-charts/legacy'
Vue.component('g-chart', GChart)

// Plugins
import AppStorage from './plugins/storage'
Vue.use(AppStorage)

import Cart from './plugins/cart'
Vue.use(Cart)

import ApiResponse from './plugins/api-response'
Vue.use(ApiResponse)

import './theme'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
