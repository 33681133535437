<template>
  <div class="input-group mb-2">
    <span class="input-group-text"><i :class="icon"></i></span>
    <input :type="type" class="form-control" :placeholder="placeholder" v-model="val" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: this.value
    }
  },

  props: {
    icon: {
      default() {
        return 'fa fa-user'
      }
    },

    value: {
      default() {
        return null
      }
    },

    placeholder: {
      default() {
        return 'Amount: 0'
      }
    },

    type: {
      default() {
        return 'number'
      }
    }
  },

  watch: {
    val() {
      this.$emit('input', this.val)
    }
  }
};
</script>
