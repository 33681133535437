export default {
  install(Vue) {
    Vue.storage = {
      storage_key: process.env.VUE_APP_NAME,

      set(key, value) {
        localStorage.setItem(`${this.storage_key}_${key}`, value)
      },

      get(key) {
        return localStorage.getItem(`${this.storage_key}_${key}`)
      },

      has(key) {
        return this.getItem(key) != null
      },

      remove(key) {
        localStorage.removeItem(`${this.storage_key}_${key}`)
      }
    };

    Object.defineProperties(Vue.prototype, {
      $storage: {
        get() {
          return Vue.storage;
        },
      },
    });
  },
};