export default {
  install(Vue) {
    Vue.cart = {
      storage_key: process.env.VUE_APP_NAME,

      add(item, amount, settings) {
        let current = this.get();

        current.push({
          id: item.id,
          amount: amount,
          logo: item.logo,
          name: item.name,
          org: item.organization,
          available: item.credits,
          price: settings.price,
        });

        localStorage.setItem(
          `${this.storage_key}_cart`,
          JSON.stringify(current)
        );
      },

      set(cart) {
        localStorage.setItem(
          `${this.storage_key}_cart`,
          JSON.stringify(cart)
        );
      },

      get() {
        let current = [];

        if (localStorage.getItem(`${this.storage_key}_cart`)) {
          current = JSON.parse(
            localStorage.getItem(`${this.storage_key}_cart`)
          );
        } else {
          current = [];
        }

        return current;
      },

      removeByKey(key) {
        let current = this.get();

        current = current.filter((itm, i) => {
          return key != i;
        });

        localStorage.setItem(
          `${this.storage_key}_cart`,
          JSON.stringify(current)
        );
      },

      remove(item) {
        let current = this.get();

        current = current.filter((itm) => {
          return item.id != itm.id;
        });

        localStorage.setItem(
          `${this.storage_key}_cart`,
          JSON.stringify(current)
        );
      },

      empty() {
        localStorage.setItem(
          `${this.storage_key}_cart`,
          JSON.stringify([])
        );
      }
    };

    Object.defineProperties(Vue.prototype, {
      $cart: {
        get() {
          return Vue.cart;
        },
      },
    });
  },
};
